import { Modal } from "antd";
import React, { memo } from "react";

const CustomModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        title={props.title}
        onCancel={props.onCancel}
        width={props.width}
      >
        {props.children}
      </Modal>
    </div>
  );
};

export default memo(CustomModal);
