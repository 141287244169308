export const pendingUserColumn = [
  {
    title: "Username",
    dataIndex: "first_name",
    width: 100,
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 100,
  },
  {
    title: "Company name",
    dataIndex: "company_name",
    width: 140,
  },
  {
    title: "Department",
    dataIndex: "department_name",
    width: 160,
  },
  {
    title: "Role",
    dataIndex: "role",
    width: 100,
  },
];
