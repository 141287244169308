import { InputNumber } from "antd";
import React from "react";

const CustomInputNumber = (props) => {
  return (
    <div className="flex flex-col gap-1">
      <label>{props.label}</label>
      <InputNumber
        min={props.min}
        max={props.max}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        className={props.className}
      />
    </div>
  );
};

export default CustomInputNumber;
