import React from "react";
import CustomButton from "../../Custom/CustomButton";
import { useNavigate } from "react-router-dom";
import CelebrateIcon from "../../Images/celebrate_icon.svg";

const ThankYou = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="absolute top-8 right-8">
          <CustomButton title={<>Logout</>} onClick={handleNavigate} />
        </div>
        <img src={CelebrateIcon} alt="Tick" className="w-40" />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Thank you for completing the survey
        </h1>
        <p className="text-lg text-gray-600 mb-5">
          You have successfully completed the survey you’ll be getting your
          scorecard in your mail.
        </p>
        <div className="flex justify-center items-center gap-5">
          <CustomButton
            className="border border-[#5D5FEF] bg-white w-full text-[#5D5FEF] px-8 py-3 hover:bg-[#5D5FEF] text-base font-medium hover:text-white justify-start cursor-pointer block  rounded-md mr-4"
            title={<>Re-Assessment</>}
            onClick={() => {
              navigate("/assessment");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
