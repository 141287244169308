import React from "react";
import { Navigate, Route, Routes } from "react-router-dom/dist";
import Register from "./pages/Register";
import Assessment from "./pages/Assessment";
import LoginChangePassword from "./pages/LoginChangePassword";
import LoginResetPassword from "./pages/LoginResetPassword";
import UnauthorizedPage from "./pages/UnAuthorized";
import Cookies from "js-cookie";
import ThankYou from "./pages/ThankYou";
import { ESG_TOKEN, Master_User, Super_User } from "./Assets/Constant";
import UserManagement from "./pages/UserManagement";
import Reports from "./pages/Reports";
import UserDetailsApprove from "./pages/UserDetailsApprove";
import QuestionManagement from "./pages/QuestionManagement";
import CreateQuestion from "./pages/CreateQuestion";
import ReportDetails from "./pages/ReportDetails";
import ConsolidatorManagement from "./pages/ConsolidatorManagement";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProtectedRoutes from "./Layout/ProtectedRoutes";
import NotFoundPage from "./pages/NotFound";
import ProtectedRoles from "./Layout/ProtectedRoles";
import Sidebar from "./components/Sidebar";

const App = () => {
  const userRole = localStorage.getItem("userRole");

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            Cookies.get(ESG_TOKEN) &&
            (userRole === Super_User || userRole === Master_User) ? (
              <Navigate to="/users" />
            ) : (
              <LoginResetPassword />
            )
          }
        />
        <Route path="*" element={<NotFoundPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route element={<Sidebar />}>
            <Route element={<ProtectedRoles />}>
              <Route path="/users" element={<UserManagement />} />
              <Route path="/users/:userId" element={<UserDetailsApprove />} />
              <Route
                path="/questionmanagement"
                element={<QuestionManagement />}
              />
              <Route path="/createquestion" element={<CreateQuestion />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/:reportId" element={<ReportDetails />} />
              <Route path="/reportdetails" element={<ReportDetails />} />
            </Route>
            <Route path="/consolidator" element={<ConsolidatorManagement />} />
          </Route>
          <Route path="/assessment" element={<Assessment />} />
        </Route>
        <Route path="/thanks" element={<ThankYou />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/changepassword" element={<LoginChangePassword />} />
        <Route path="/Register" element={<Register />} />
      </Routes>
    </div>
  );
};

export default App;
