import * as Yup from "yup";
import { Super_User } from "../Constant";

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .required("Email is required"),
  password: Yup.string()
    .max(30, "Password cannot exceed 30 characters")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    )
    .required("Password is required"),
});

export const addUserValidationSchema = Yup.object().shape({
  organisation: Yup.string().required("Organisation is required"),
  username: Yup.string()
    .matches(
      /^[a-zA-Z/s]*([!@#$%^&*()_+{}[\]:;<>,.?~\\/-])?$/,
      "Username can only contain alphabets and symbol"
    )
    .required("Organisation is required"),
  email: Yup.string().required("Organisation is required"),
  user_role: Yup.string().required("Organisation is required"),
  department: Yup.string().required("Organisation is required"),
});

export const addQuestionnaireValidationSchema = Yup.object().shape({
  organisation: Yup.string().required("Organisation is required"),
  title: Yup.string()
    .matches(
      /^[a-zA-Z/s]*([!@#$%^&*()_+{}[\]:;<>,.?~\\/-])?$/,
      "Username can only contain alphabets and symbol"
    )
    .required("Organisation is required"),
  department: Yup.string().required("Organisation is required"),
});

export const registerValidationSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is required"),
    regulation: Yup.array()
      .min(1, "Regulation is required")
      .required("Regulation is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      )
      .required("Password is required"),
  }),
  company_name: Yup.string().required("Company name is required"),
  organization_based: Yup.string().required("Organization based is required"),
  organization_sector: Yup.array()
    .min(1, "Organization sector is required")
    .required("Organization sector is required"),
  no_of_employees: Yup.string().required("No of employees required"),
});

export const MasterUserValidationSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is required"),
    valid_till: Yup.string().required("Valid till is required"),
    password: Yup.string()
      .max(30, "Password cannot exceed 30 characters")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      )
      .required("Password is required"),
    regulation: Yup.array()
      .min(1, "Regulation is required")
      .required("Regulation is required"),
    section: Yup.array()
      .min(1, "Section is required")
      .required("Section is required"),
    liscense_count: Yup.string(),
  }),
  company_name: Yup.string().required("Company name is required"),
  organization_based: Yup.string().required("Organization based is required"),
  organization_sector: Yup.array()
    .min(1, "Organization sector is required")
    .required("Organization sector is required"),
  no_of_employees: Yup.string().required("No of employees required"),
});
const userRole = localStorage.getItem("userRole");
export const createQuestionValidationSchema = Yup.object().shape({
  question: Yup.string()
    .required("Question is required")
    .max(1000, "It exceeds the maximum character."),
  remarks: Yup.string()
    .required("Remarks is required")
    .max(1000, "It exceeds the maximum character."),
  department: Yup.array()
    .min(1, "Department is required")
    .required("Department is required"),
  section: Yup.string().required("Section is required"),
  sub_section: Yup.string().required("Sub section is required"),
  organization_sector:
    userRole === Super_User
      ? Yup.array()
          .min(1, "Organization sector is required")
          .required("Organization sector is required")
      : Yup.array(),
  regulation: Yup.array()
    .min(1, "Regulation is required")
    .required("Regulation is required"),
  answer_type: Yup.string().required("Answer type is required"),
});

export const addSubUserValidationSchema = (userId) =>
  Yup.object().shape({
    user: Yup.object().shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Email is required"),
      password: userId
        ? Yup.string()
        : Yup.string()
            .max(30, "Password cannot exceed 30 characters")
            .min(6, "Password must be at least 6 characters")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
              "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
            )
            .required("Password is required"),

      name: Yup.string().required("Department is required"),
    }),
  });

export const ChangePasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address"),
  old_password: Yup.string()
    .max(30, "Password cannot exceed 30 characters")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    )
    .required("Old Password is required"),
  new_password: Yup.string()
    .min(4, "New Password must be at least 4 characters")
    .notOneOf(
      [Yup.ref("old_password")],
      "New Password must be different from Old Password"
    )
    .max(30, "New Password cannot exceed 30 characters")
    .required("New Password is required"),
});

export const VerifyOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .length(6, "OTP must be exactly 6 characters")
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .required("OTP is required"),
});

export const ResetPasswordValidationSchema = Yup.object().shape({
  new_password: Yup.string()
    .max(30, "Password cannot exceed 30 characters")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    )
    .required("New Password is required"),

  confirm_new_password: Yup.string()
    .min(4, "Confirm New Password must be at least 4 characters")
    .oneOf(
      [Yup.ref("new_password")],
      "Confirm New Password must be same as New Password"
    )
    .max(30, "Confirm New Password cannot exceed 30 characters")
    .required("New Password is required"),
});

export const VerifyEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
