import React from "react";
import CustomButton from "../../Custom/CustomButton";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        Unauthorized Access
      </h1>
      <p className="text-lg text-gray-600 mb-5">
        You are not authorized to access this page.
      </p>
      <CustomButton title="Back" onClick={handleNavigate} />
    </div>
  );
};

export default UnauthorizedPage;
