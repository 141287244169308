import api from "../Composables/api";

class ApiCallService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAllReport() {
    try {
      const response = await api.get("/reports/");
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getSectionWiseReport(url) {
    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}

export default ApiCallService;
