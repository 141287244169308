import React from "react";

const CustomButton = (props) => {
  return (
    <div>
      <button
        type={props.type}
        disabled={props.disabled}
        className={`${
          props.className
            ? props.className
            : `text-white hover:text-white ${
                props.disabled
                  ? "bg-[#c1c2f5] cursor-help"
                  : "bg-[#5D5FEF] hover:bg-[#7c7eec]"
              } hover:bg-[#7c7eec]  border-none `
        } text-center cursor-pointer block px-5 py-2.5 rounded-md `}
        onClick={props.onClick}
      >
        {props.children} {props.title}
      </button>
    </div>
  );
};

export default CustomButton;
