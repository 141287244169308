import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../Custom/CustomMessageModal";
import login_background_image from "../../Images/Login.png";
import Input from "../../Custom/LoginInput";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import {
  LoginValidationSchema,
  ResetPasswordValidationSchema,
  VerifyEmailSchema,
  VerifyOtpSchema,
} from "../../Assets/ValidationSchema";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import api from "../../Composables/api";
import CustomCheckBox from "../../Custom/CheckBox";
import Cookies from "js-cookie";
import {
  ESG_TOKEN,
  Master_User,
  Super_User,
  consolidator_user,
} from "../../Assets/Constant";
import axios from "axios";
import Logo from "../../Images/GreenWorldLog.png";

const LoginResetPassword = () => {
  const [flow, setFlow] = useState("Login");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [role, setRole] = useState("");
  const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
  const [otpSucessOpen, setOtpSucessOpen] = useState(false);
  const [mailSentOpen, setMailSentOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const baseUrl = process.env.REACT_APP_API_KEY;

  const handleClose = () => {
    setOpen(false);
    if (role === Super_User) {
      navigate("/users");
    } else if (role === Master_User) {
      navigate("/users");
    } else if (role === consolidator_user) {
      navigate("/consolidator");
    } else {
      navigate("/assessment");
    }
  };

  const closeModal = (value) => {
    setOpen(value);
    if (role === Super_User) {
      navigate("/users");
    } else if (role === Master_User) {
      navigate("/users");
    } else if (role === consolidator_user) {
      navigate("/consolidator");
    } else {
      navigate("/assessment");
    }
  };

  const handleClosed = () => {
    setOpen2(false);
  };

  const closeModal1 = (value) => {
    setOpen2(value);
  };

  // -------OPEN AND CLOSE MODAL HANDLER FOR RESET PASSWORD SUCCESS MESSAGE-----
  const handleResetSuccessClose = () => {
    setResetSuccessOpen(false);
    setOpen(false);
    setFlow("Login");
  };

  const closeModalResetSucesss = (value) => {
    setResetSuccessOpen(value);
    setOpen(false);
    setFlow("Login"); // Redirect to login page
  };

  //------------OPEN AND CLOSE MODAL HANDLER FOR EMAIL SENT SUCCESS MESSAGE------------
  const handleMailSentClose = () => {
    setMailSentOpen(false);
    setOpen(false);
    setFlow("verifyOtp");
  };

  const closeModalMailSent = (value) => {
    setMailSentOpen(value);
    setOpen(false);
    setFlow("verifyOtp");
  };

  // ------OPEN AND CLOSE MODAL HANDLER FOR OTP SUCCESS MESSAGE------
  const handleOtpSuccessClose = () => {
    setOtpSucessOpen(false);
    setOpen(false);
    setFlow("reset");
  };

  const closeModalOtpSuccess = (value) => {
    setOtpSucessOpen(value);
    setOpen(false);
    setFlow("reset");
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axios.post(`${baseUrl}login/`, values);
        localStorage.setItem("userRole", response?.data?.role);
        setRole(response?.data?.role);
        setOpen(true);
        setLoading(false);
        Cookies.set(ESG_TOKEN, response.data.token.access, { expires: 3 });
      } catch (error) {
        setOpen2(true);
        setErrorMsg(error?.response?.data?.message);
        setLoading(false);
        console.error("Error:", error);
      }
    },
  });

  const usermail = sessionStorage.getItem("userMail");

  //-----------  RESET PASSWORD FORMIK LOGIC AND API CALLS -----------
  const resetPasswordFormik = useFormik({
    initialValues: {
      email: usermail,
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: ResetPasswordValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        const response = await api.post("/reset-password/", values);
        sessionStorage.clear();
        Cookies.remove(ESG_TOKEN);
        setResetSuccessOpen(true);
        resetPasswordFormik.resetForm();
        setFlow("Login");
      } catch (error) {
        setOpen2(true);
        setErrorMsg(error?.response?.data?.message);
        console.error("Error:", error);
      }
    },
  });

  const VerifyEmailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: VerifyEmailSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        sessionStorage.setItem("userMail", values.email);
        const response = await axios.post(
          `${baseUrl}reset-password-otp/`,
          values
        );
        VerifyEmailFormik.resetForm();
        setMailSentOpen(true);
        setFlow("verifyOtp");
      } catch (error) {
        setOpen2(true);
        setErrorMsg(error?.response?.data?.message);
        console.error("Error:", error);
      }
    },
  });

  //---------Verify OTP FORMIK -------------
  const VerifyOtpFormik = useFormik({
    initialValues: {
      email: usermail,
      otp: "",
    },
    validationSchema: VerifyOtpSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(`${baseUrl}verify-otp/`, values);
        setOtpSucessOpen(true);
        Cookies.set(ESG_TOKEN, response?.data?.token?.access);
        VerifyOtpFormik.resetForm();
      } catch (error) {
        setOpen2(true);
        setErrorMsg(error?.response?.data?.message);
        console.error("Error:", error);
      }
    },
  });

  return (
    <>
      <div className="grid sm:grid-cols-1 grid-cols-1 md:grid-cols-2 md:p-0 sm:p-5 p-5 ">
        <CustomModal
          open={open}
          handleClose={handleClose}
          children={
            <SuccessMessage
              img={Tick}
              message="Logged in Successfull !"
              desc="Great to hear that you've successfully registered!"
              colors="bg-[#32D52B]"
              modalClose={closeModal}
            />
          }
        />

        <CustomModal
          open={open2}
          handleClose={handleClosed}
          children={
            <SuccessMessage
              img={Cross}
              message={errorMsg}
              colors="bg-[#F73232]"
              modalClose={closeModal1}
            />
          }
        />

        <CustomModal
          open={resetSuccessOpen}
          handleClose={handleResetSuccessClose}
          children={
            <SuccessMessage
              img={Tick}
              message="Password Reset Successful!"
              desc="Please Login with new password "
              colors="bg-[#32D52B]"
              modalClose={closeModalResetSucesss}
            />
          }
        />

        <CustomModal
          open={mailSentOpen}
          handleClose={handleMailSentClose}
          children={
            <SuccessMessage
              img={Tick}
              message="Email sent Successful!"
              desc="Please check your mail for OTP"
              colors="bg-[#32D52B]"
              modalClose={closeModalMailSent}
            />
          }
        />

        <CustomModal
          open={otpSucessOpen}
          handleClose={handleOtpSuccessClose}
          children={
            <SuccessMessage
              img={Tick}
              message="OTP verification Successful!"
              desc="Your can now change your password now."
              colors="bg-[#32D52B]"
              modalClose={closeModalOtpSuccess}
            />
          }
        />

        <div className="w-full md:order-1 sm:order-2 order-2  flex items-center max-h-screen ">
          <img
            src={login_background_image}
            alt=""
            className="max-h-screen w-full "
          />
        </div>
        <div className="w-[100%] h-screen md:order-2 sm:order-1 order-1  flex items-center justify-center">
          <div className=" w-[100%] md:w-[70%] bg-white p-6 mt-6 rounded-xl shadow-2xl">
            {flow === "Login" ? (
              //------------------------------- LOGIN FORM ---------------------------------

              <form
                onSubmit={formik.handleSubmit}
                className="w-full mt-8 flex flex-col gap-5"
              >
                <h1 className="text-[#5D5FEF] items-center flex justify-center gap-3 py-3 px-4  text-2xl">
                  <img src={Logo} alt="" width="200px" />
                </h1>
                <div>
                  <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
                    Log in
                  </h1>
                  <p className="text-base  text-[#B2B2B2]  font-normal">
                    Please login to continue to your account.
                  </p>
                </div>
                <Input
                  type="text"
                  placeholder="Email"
                  title="Email"
                  className="w-100"
                  name="email"
                  id="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
                <Input
                  type="text"
                  isShowSecureText
                  placeholder="Password"
                  title="Password"
                  className="w-100"
                  name="password"
                  id="password"
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && formik.errors.password}
                />
                <div className="flex justify-between items-center">
                  <CustomCheckBox label="Keep me logged in" />
                  <h6
                    className="text-dark underline text-base font-normal text-end hover:cursor-pointer"
                    onClick={() => setFlow("verifyEmail")}
                  >
                    Forgot Password
                  </h6>
                </div>
                <button
                  className="bg-[#5D5FEF] hover:bg-[#7c7eec] cursor-pointer w-full py-2 text-center rounded-xl mb-4 text-white font-medium text-xl border-0"
                  type="submit"
                >
                  {loading ? "Loading .." : "Log in"}
                </button>
                <div className="flex justify-center text-[#6C6C6C]">
                  Need an account? &nbsp;
                  <Link
                    to="/Register"
                    className="text-[#5D3587] underline font-semibold"
                  >
                    Create one
                  </Link>
                </div>
              </form>
            ) : flow === "reset" ? (
              //----------------------------- RESET PASSWORD FORM -------------------------------
              <form
                onSubmit={resetPasswordFormik.handleSubmit}
                className="w-full mt-8 flex flex-col gap-5"
              >
                <div>
                  <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
                    Reset Password
                  </h1>
                  <p className="text-base  text-[#B2B2B2]  font-normal">
                    Reset your password to continue
                  </p>
                </div>

                <Input
                  type={
                    resetPasswordFormik.values.new_password
                      ? "text"
                      : "password"
                  }
                  isShowSecureText
                  placeholder="New Password"
                  title="New Password"
                  className="w-100"
                  name="new_password"
                  id="new_password"
                  required
                  value={resetPasswordFormik.values.new_password}
                  onChange={resetPasswordFormik.handleChange}
                  onBlur={resetPasswordFormik.handleBlur}
                  error={
                    resetPasswordFormik.touched.new_password &&
                    resetPasswordFormik.errors.new_password
                  }
                />
                <Input
                  type={
                    resetPasswordFormik.values.confirm_new_password
                      ? "text"
                      : "password"
                  }
                  isShowSecureText
                  placeholder="Confirm New Password"
                  title="Confirm New Password"
                  className="w-100"
                  name="confirm_new_password"
                  id="confirm_new_password"
                  required
                  value={resetPasswordFormik.values.confirm_new_password}
                  onChange={resetPasswordFormik.handleChange}
                  onBlur={resetPasswordFormik.handleBlur}
                  error={
                    resetPasswordFormik.touched.confirm_new_password &&
                    resetPasswordFormik.errors.confirm_new_password
                  }
                />
                <button
                  className="bg-[#5D5FEF] hover:bg-[#7c7eec] w-full py-2 text-center rounded-xl mb-4 text-white font-medium text-xl border-0"
                  type="submit"
                >
                  Reset
                </button>
                <div className="flex justify-center text-[#6C6C6C]">
                  Need an account? &nbsp;
                  <Link
                    to="/Register"
                    className="text-[#5D3587] underline font-semibold"
                  >
                    Create one
                  </Link>
                </div>
              </form>
            ) : flow === "verifyEmail" ? (
              //---------------------------------VERIFY EMAIL FORM -------------------------------------
              <form
                onSubmit={VerifyEmailFormik.handleSubmit}
                // onSubmit={resetPasswordFormik.handleSubmit}
                className="w-full mt-8 flex flex-col gap-5"
              >
                <div className=" flex flex-col gap-5">
                  <p
                    className="text-base  font-normal cursor-pointer hover:underline hover:text-blue-500"
                    onClick={() => setFlow("Login")}
                  >
                    Back
                  </p>
                  <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
                    Verify Email
                  </h1>
                  <p className="text-base  text-[#B2B2B2]  font-normal">
                    Please verify email to continue to your account.
                  </p>
                </div>
                <Input
                  type="text"
                  placeholder="Email"
                  title="Email"
                  className="w-100"
                  name="email"
                  id="email"
                  required
                  value={VerifyEmailFormik.values.email}
                  onChange={VerifyEmailFormik.handleChange}
                  onBlur={VerifyEmailFormik.handleBlur}
                  error={
                    VerifyEmailFormik.touched.email &&
                    VerifyEmailFormik.errors.email
                  }
                />

                <button
                  className="bg-[#5D5FEF] hover:bg-[#7c7eec] w-full py-2 text-center rounded-xl mb-4 text-white font-medium text-xl border-0"
                  type="submit"
                >
                  Submit
                </button>
                <div className="flex justify-center text-[#6C6C6C]">
                  Need an account? &nbsp;
                  <Link
                    to="/Register"
                    className="text-[#5D3587] underline font-semibold"
                  >
                    Create one
                  </Link>
                </div>
              </form>
            ) : flow === "verifyOtp" ? (
              //---------------------------------VERIFY OTP FORM -------------------------------------
              <form
                onSubmit={VerifyOtpFormik.handleSubmit}
                className="w-full mt-8 flex flex-col gap-5"
              >
                <div className=" flex flex-col gap-5">
                  <p
                    className="text-base  font-normal cursor-pointer hover:underline hover:text-blue-500"
                    onClick={() => setFlow("verifyEmail")}
                  >
                    Back
                  </p>
                  <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
                    Verify OTP
                  </h1>
                  <p>
                    Please enter OTP from your email to continue to your
                    account.
                  </p>
                </div>
                <Input
                  type="text"
                  placeholder="OTP"
                  title="OTP"
                  className="w-100"
                  name="otp"
                  id="otp"
                  required
                  value={VerifyOtpFormik.values.otp}
                  onChange={VerifyOtpFormik.handleChange}
                  onBlur={VerifyOtpFormik.handleBlur}
                  error={
                    VerifyOtpFormik.touched.otp && VerifyOtpFormik.errors.otp
                  }
                />

                <button
                  className="bg-[#5D5FEF] hover:bg-[#7c7eec] w-full py-2 text-center rounded-xl mb-4 text-white font-medium text-xl border-0"
                  type="submit"
                >
                  Verify
                </button>
                <div className="flex justify-center text-[#6C6C6C]">
                  Need an account? &nbsp;
                  <Link
                    to="/Register"
                    className="text-[#5D3587] underline font-semibold"
                  >
                    Create one
                  </Link>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginResetPassword;
