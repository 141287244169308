import React from "react";
import Modal from "@mui/material/Modal";

export default function CustomModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex items-center justify-center"
    >
      <div className="bg-white p-6 rounded-xl shadow-2xl">{props.children}</div>
    </Modal>
  );
}
