import React, { useEffect, useState, useCallback, memo } from "react";
import CustomTable from "../../Custom/CustomTable";
import CustomButton from "../../Custom/CustomButton";
import { LogoutOutlined, PlusOutlined } from "@ant-design/icons";
import CustomModal from "../../Custom/Modal";
import { consolidatorColumn } from "./Assets";
import CustomHeader from "../../Custom/CustomHeader";
import AddUser from "../../components/AddUser";
import Cookies from "js-cookie";
import api from "../../Composables/api";
import useModalOpen from "../../Custom/CustomModalOpen/useModalOpen";
import { ESG_TOKEN } from "../../Assets/Constant";

const ConsolidatorManagement = () => {
  const [masterUserInfo, setMasterUserInfo] = useState([]);
  const [consolidatorRegulation, setConsolidatorRegulations] = useState([]);
  const [consolidatorDate, setConsolidatorDate] = useState("");
  const [totalLicenseCount, setTotalLicenseCount] = useState("");
  const onSearch = (value) => {
    console.log(value);
  };

  const [open, showModal, handleClose, closeModal] = useModalOpen();

  const getConsolidatorInfo = useCallback(async () => {
    try {
      const consolidatorInfo = await api.get("/consolidator_dashboard/");
      setConsolidatorDate(consolidatorInfo?.data?.consolidator?.valid_till);
      setConsolidatorRegulations(
        consolidatorInfo?.data?.consolidator?.regulation
      );
      setMasterUserInfo(consolidatorInfo?.data?.organization_master_users);
      setTotalLicenseCount(
        consolidatorInfo?.data?.consolidator?.liscense_count?.toString()
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getConsolidatorInfo();
  }, []);

  const handleLogout = () => {
    Cookies.remove(ESG_TOKEN, { path: "/" });
    localStorage.clear();
    window.location.href = "/";
  };

  const handleApiCall = () => {
    getConsolidatorInfo();
  };

  return (
    <>
      <CustomHeader>
        {" "}
        <CustomButton title="Logout" onClick={handleLogout}>
          <LogoutOutlined />
        </CustomButton>
      </CustomHeader>
      <CustomModal open={open} title="Add Master User" onCancel={handleClose}>
        <AddUser
          modalClose={closeModal}
          apiCall={handleApiCall}
          licenseDate={consolidatorDate}
          regulation={consolidatorRegulation}
        />
      </CustomModal>
      <div className="p-5">
        <div className="flex justify-between">
          <h2>Consolidator Management</h2>
          <h2>Licence: {totalLicenseCount}</h2>
          <CustomButton title="Add Master User" onClick={showModal}>
            <PlusOutlined />
          </CustomButton>
        </div>

        <div className="mt-5">
          <CustomTable
            columnData={consolidatorColumn}
            data={masterUserInfo}
            scrollWidth="1500"
          />
        </div>
      </div>
    </>
  );
};

export default memo(ConsolidatorManagement);
