import React, { useState } from "react";

const useModalOpen = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeModal = (value) => {
    setOpen(value);
  };

  return [open, showModal, handleClose, closeModal];
};

export default useModalOpen;
