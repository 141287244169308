import React from "react";
import { Tabs } from "antd";

const CustomTab = (props) => {
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={props.items}
        activeKey={props.activeKey}
        onChange={props.onChange}
      ></Tabs>
    </>
  );
};

export default CustomTab;
