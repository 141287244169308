import { Divider, Radio, Tooltip } from "antd";
import React, { useState } from "react";
import { Master_User } from "../../Assets/Constant";

const AssessmentQuestion = (props) => {
  const [showRemarks, setShowRemarks] = useState(null);
  const userRole = localStorage.getItem("userRole");

  const openRemarks = (id) => {
    setShowRemarks((prevId) => (prevId === id ? null : id));
  };

  const handleChange = async (
    questionId,
    { target: { value } },
    dependents
  ) => {
    props.onChange(questionId, { target: { value } }, dependents);
  };

  const option_type = {
    "Yes/No/NA": props.plainOptions,
    "Yes/No": props.plainOption,
  };

  return (
    <div>
      <div className=" flex flex-col bg-[#fff] rounded-2xl p-5 w-[100%]">
        {props.activeIndex !== null &&
          props.data
            ?.filter(
              (item) =>
                item.sub_section === props.uniqueSubSections[props.activeIndex]
            )
            .map((item, index) => (
              <div key={index} className="flex flex-col gap-2 relative">
                <div
                  onClick={() => openRemarks(item?.id)}
                  className="cursor-pointer"
                >
                  <p
                    className={`text-[#5D5FEF] absolute right-5 top-0 font-bold border-solid border-[#5D5FEF] rounded-[50%] px-2 `}
                  >
                    i
                  </p>
                </div>
                <h3 className="text-[#5D5FEF]">Question {index + 1}</h3>
                <p className="text-[#000]">{item?.question}</p>

                <Radio.Group
                  options={
                    item?.answer_type === "Range"
                      ? Object?.keys(item?.score?.range)
                      : option_type[item?.answer_type]
                  }
                  disabled={props.disabled}
                  onChange={(value) =>
                    handleChange(
                      item?.id,
                      value ?? item?.response,
                      item?.dependents
                    )
                  }
                  value={props?.values[item?.id] || item?.response || ""}
                />
                {userRole === Master_User && (
                  <div className="flex mt-2 justify-start gap-5">
                    <div className="flex flex-col gap-1">
                      <p className="text-black text-xs font-semibold whitespace-nowrap">
                        Yes
                      </p>
                      <p className="text-stone-500 text-xs">
                        {item?.answers?.Yes?.join(", ")}
                      </p>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-black text-xs font-semibold whitespace-nowrap">
                        No
                      </p>
                      <p className="text-stone-500 text-xs">
                        {item?.answers?.No?.join(", ")}
                      </p>
                    </div>
                    {item?.allow_na && (
                      <div className="flex flex-col gap-1">
                        <p className="text-black text-xs font-semibold whitespace-nowrap">
                          NA
                        </p>
                        <p className="text-stone-500 text-xs">
                          {item?.answers?.NA?.join(", ")}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                <div className="flex justify-start md:gap-5 gap-2">
                  <div className="flex flex-col gap-1">
                    <p className="text-black text-xs font-semibold whitespace-nowrap">
                      Department
                    </p>
                    <p className="text-stone-500 text-xs">
                      {item?.department.join(", ")}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-black text-xs font-semibold whitespace-nowrap">
                      Regulation
                    </p>
                    <p className="text-stone-500 text-xs">
                      {item?.regulation.length > 5 ? (
                        <Tooltip
                          title={
                            item?.regulation ? item.regulation.join(", ") : ""
                          }
                        >
                          <span>
                            {item?.regulation
                              ? item.regulation.slice(0, 5).join(", ") + "..."
                              : ""}
                          </span>
                        </Tooltip>
                      ) : (
                        <span>{item?.regulation?.join(",")}</span>
                      )}
                    </p>
                  </div>
                  {userRole === Master_User && (
                    <div className="flex flex-col gap-1">
                      <p className="text-black text-xs font-semibold whitespace-nowrap">
                        Organisation
                      </p>
                      <p className="text-stone-500 text-xs">
                        {item?.organization_sector?.join(",")}
                      </p>
                    </div>
                  )}
                </div>
                {showRemarks === item?.id && (
                  <div>
                    <p className="text-[#666666] mt-3">{item.remarks}</p>
                  </div>
                )}
                <Divider />
              </div>
            ))}
      </div>
    </div>
  );
};

export default AssessmentQuestion;
