import React, { useState } from "react";
import {
  EditFilled,
  FundFilled,
  HomeFilled,
  OrderedListOutlined,
} from "@ant-design/icons";
import { Avatar, Layout, Menu, Popconfirm } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "../../Images/GreenWorldLog.png";
import Logout from "../../Images/Logout.svg";
import { ESG_TOKEN, Master_User, Super_User } from "../../Assets/Constant";

const { Content, Sider } = Layout;

const label = ["Home", "Reports", "Question Management", "Start Assessment"];
const url = ["/users", "/reports", "/questionmanagement", "/assessment"];

const items = [HomeFilled, FundFilled, OrderedListOutlined, EditFilled].map(
  (icon, index) => ({
    key: url[index],
    icon: React.createElement(icon),
    label: label[index],
    url: url[index],
  })
);

const Sidebar = (props) => {
  const [headerButton, setHeaderButton] = useState(null);
  const currentRoute = window.location.pathname;
  const setButton = (button) => {
    setHeaderButton(button);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove(ESG_TOKEN, { path: "/" });
    localStorage.clear();
    window.location.href = "/";
  };
  const userRole = localStorage.getItem("userRole");

  const handleNavigate = () => {
    if (userRole === Super_User || userRole === Master_User) {
      navigate("/users");
    } else {
      navigate("/");
    }
  };
  return (
    <>
      {/* {[
        "/users",
        "/home",
        "/users/:userId",
        "/questionmanagement",
        "/createquestion",
        "/questionnaire",
        "/consolidator",
        "/organisation",
        "/reports",
        "/reportdetails",
      ].includes(currentRoute) && ( */}
      <>
        <Layout hasSider className="mt-0.5 relative flex">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            className="flex flex-col justify-between"
            style={{
              height: "100vh",
              top: "0",
              bottom: "0",
              position: "sticky",
              zIndex: "5",
              background: "#F7FAFC",
              minWidth: "240px",
            }}
          >
            <h1
              className="text-[#5D5FEF] items-center flex justify-center gap-3   py-1 px-4 cursor-pointer text-2xl"
              onClick={handleNavigate}
            >
              <img src={Logo} alt="" width="200px" />
            </h1>
            <div className="demo-logo-vertical  h-[80vh] flex flex-col  justify-between">
              <Menu
                mode="inline"
                className="bg-[#F7FAFC]"
                defaultSelectedKeys={[currentRoute]}
              >
                {items?.map(
                  (item) =>
                    !(
                      userRole === Super_User && item.url === "/assessment"
                    ) && (
                      <Menu.Item key={item.url} icon={item.icon}>
                        <Link to={item.url}>{item.label}</Link>
                      </Menu.Item>
                    )
                )}
              </Menu>
              <div className=" items-center h-[10vh] gap-2 px-3 py-2 rounded-3xl">
                <div className=" flex items-center justify-between gap-2 hover:bg-slate-200 py-2 rounded-xl">
                  <Avatar>S</Avatar>
                  <div className="justify-center items-stretch flex grow basis-[0%] flex-col">
                    <div className="text-neutral-900 text-sm font-medium tracking-tight whitespace-nowrap">
                      {userRole}
                    </div>
                  </div>
                </div>
                <Popconfirm
                  title="Logout"
                  description="Are you sure to you want to logout?"
                  onConfirm={handleLogout}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="items-stretch flex hover:bg-slate-200 justify-between gap-3 px-5 py-3 rounded-xl">
                    <img loading="lazy" alt="" src={Logout} />
                    <div className="text-red-700 cursor-pointer text-base leading-4 tracking-tight self-center grow whitespace-nowrap my-auto">
                      Log out
                    </div>
                  </div>
                </Popconfirm>
              </div>
            </div>
          </Sider>
          <Layout>
            <Content className="lg:relative absolute bg-white top-0 left-0 w-full">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </>
      {/* )} */}
    </>
  );
};
export default Sidebar;
