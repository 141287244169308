import axios from "axios";
import Cookies from "js-cookie";
import { ESG_TOKEN } from "../Assets/Constant";

const baseURL = process.env.REACT_APP_API_KEY;

export const api = axios.create({
  baseURL,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get(ESG_TOKEN);

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor (remains the same)
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      Cookies.remove(ESG_TOKEN);
    } else if (error?.response?.status === 500) {
      console.error("Internal Server Error");
    }
    return Promise.reject(error);
  }
);

export default api;
