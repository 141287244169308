import React from "react";
import ReactApexChart from "react-apexcharts";

const CustomSectionChart = (props) => {
  const formatSection = (section) => {
    return section.replace(/([A-Z])/g, " $1").trim();
  };

  const barChartData = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: props?.section?.map(formatSection),
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          endingShape: "flat",
        },
      },
      colors: ["#0078D4"],
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
    },
    series: [
      {
        name: "Scores",
        data: props?.subSection,
      },
    ],
  };

  return (
    <ReactApexChart
      options={barChartData.options}
      series={barChartData.series}
      type="bar"
      height={300}
    />
  );
};

export default CustomSectionChart;
