import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../Custom/CustomMessageModal";
import login_background_image from "../../Images/Login.png";
import Input from "../../Custom/LoginInput";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import { ChangePasswordValidationSchema } from "../../Assets/ValidationSchema";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import api from "../../Composables/api";
import CustomCheckBox from "../../Custom/CheckBox";

const LoginChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const closeModal = (value) => {
    setOpen(value);
    navigate("/");
  };

  const handleClosed = () => {
    setOpen2(false);
  };

  const closeModal1 = (value) => {
    setOpen2(value);
  };

  const changePasswordFormik = useFormik({
    initialValues: {
      email: "",
      old_password: "",
      new_password: "",
    },

    validationSchema: ChangePasswordValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        const response = await api.post("/change-password/", values);
        changePasswordFormik.resetForm();
        setOpen(true);
      } catch (error) {
        setOpen2(true);
        console.error("Error:", error);
      }
    },
  });

  return (
    <>
      <div className="grid sm:grid-cols-1 grid-cols-1 md:grid-cols-2 md:p-0 sm:p-5 p-5 ">
        <CustomModal
          open={open}
          handleClose={handleClose}
          children={
            <SuccessMessage
              img={Tick}
              message="Password Change Successful!"
              desc="Now you can login with new password"
              colors="bg-[#32D52B]"
              modalClose={closeModal}
            />
          }
        />
        <CustomModal
          open={open2}
          handleClose={handleClosed}
          children={
            <SuccessMessage
              img={Cross}
              message=" Change password failed !"
              desc="Credentials that you used is not valid!"
              colors="bg-[#F73232]"
              modalClose={closeModal1}
            />
          }
        />

        <div className="w-full md:order-1 sm:order-2 order-2  flex items-center max-h-screen ">
          <img
            src={login_background_image}
            alt=""
            className="max-h-screen w-full "
          />
        </div>
        <div className="w-[100%] h-screen md:order-2 sm:order-1 order-1  flex items-center justify-center">
          <div className=" w-[100%] md:w-[70%] bg-white p-6 mt-6 rounded-xl shadow-2xl">
            {/* ---------------------CHANGE PASSWORD FORM------------------------- */}
            <form
              onSubmit={changePasswordFormik.handleSubmit}
              className="w-full mt-8 flex flex-col gap-5"
            >
              <div>
                <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
                  Change Password
                </h1>
                <p className="text-base  text-[#B2B2B2]  font-normal">
                  Change your password to continue
                </p>
              </div>
              <Input
                type="text"
                placeholder="Email"
                title="Email"
                className="w-100"
                name="email"
                id="email"
                required
                value={changePasswordFormik.values.email}
                onChange={changePasswordFormik.handleChange}
                onBlur={changePasswordFormik.handleBlur}
                error={
                  changePasswordFormik.touched.email &&
                  changePasswordFormik.errors.email
                }
              />

              <Input
                type={
                  changePasswordFormik.values.old_password ? "text" : "password"
                }
                isShowSecureText
                placeholder="Old Password"
                title="Old Password"
                className="w-100"
                name="old_password"
                id="old_password"
                required
                value={changePasswordFormik.values.old_password}
                onChange={changePasswordFormik.handleChange}
                onBlur={changePasswordFormik.handleBlur}
                error={
                  changePasswordFormik.touched.old_password &&
                  changePasswordFormik.errors.old_password
                }
              />
              <Input
                type={
                  changePasswordFormik.values.new_password ? "text" : "password"
                }
                isShowSecureText
                placeholder="New Password"
                title="New Password"
                className="w-100"
                name="new_password"
                id="new_password"
                required
                value={changePasswordFormik.values.new_password}
                onChange={changePasswordFormik.handleChange}
                onBlur={changePasswordFormik.handleBlur}
                error={
                  changePasswordFormik.touched.new_password &&
                  changePasswordFormik.errors.new_password
                }
              />
              <div className="flex justify-between items-center">
                <CustomCheckBox label="Keep me logged in" />
              </div>
              <button
                className="bg-[#5D5FEF] w-full py-2 text-center rounded-xl mb-4 text-white font-medium text-xl border-0"
                type="submit"
              >
                Change
              </button>
              <div className="flex justify-center text-[#6C6C6C]">
                Need an account? &nbsp;
                <Link
                  to="/Register"
                  className="text-[#5D3587] underline font-semibold"
                >
                  Create one
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginChangePassword;
