import React from "react";

const TermsConditions = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>

      <section>
        <p className="mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam eos
          assumenda nostrum, repudiandae perspiciatis vel provident rerum
          inventore deserunt velit quia ea maiores eaque sunt libero facilis
          tempore beatae, consectetur distinctio, dolor recusandae? Delectus
          beatae unde molestiae quos, saepe obcaecati nihil dicta.
        </p>
        <p className="mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam eos
          assumenda nostrum, repudiandae perspiciatis vel provident rerum
          inventore deserunt velit quia ea maiores eaque sunt libero facilis
          tempore beatae, consectetur distinctio, dolor recusandae? Delectus
          beatae unde molestiae quos, saepe obcaecati nihil dicta.
        </p>
        <p className="mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam eos
          assumenda nostrum, repudiandae perspiciatis vel provident rerum
          inventore deserunt velit quia ea maiores eaque sunt libero facilis
          tempore beatae, consectetur distinctio, dolor recusandae? Delectus
          beatae unde molestiae quos, saepe obcaecati nihil dicta.
        </p>
      </section>
    </div>
  );
};

export default TermsConditions;
