import { Navigate, Outlet } from "react-router-dom";
import { Master_User, Super_User } from "../../Assets/Constant";

const ProtectedRoles = () => {
  const userRoles = localStorage.getItem("userRole");
  const isAuthenticatedRoles =
    userRoles === Super_User || userRoles === Master_User;
  return isAuthenticatedRoles ? <Outlet /> : <Navigate to="/assessment" />;
};

export default ProtectedRoles;
