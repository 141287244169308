import React, { useState } from "react";
import api from "../../Composables/api";
import CustomButton from "../../Custom/CustomButton";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { message } from "antd";

const MasterScoreDownload = (props) => {
  const [loading, setLoading] = useState(false);

  const getAllReport = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/question_answer_list/?master_user_id=${props?.data?.user_id}`
      );
      if (!response.data || !response.data.questions) {
        throw new Error("Invalid response data");
      }
      const { questions } = response.data;

      const sections = {};
      questions.forEach((qa) => {
        if (!sections[qa.section]) {
          sections[qa.section] = {};
        }
        if (!sections[qa.section][qa.subsection]) {
          sections[qa.section][qa.subsection] = [];
        }
        sections[qa.section][qa.subsection].push(qa);
      });

      const sectionsParagraphs = Object.entries(sections).map(
        ([section, subsections]) => {
          const sectionParagraph = new Paragraph({
            children: [new TextRun({ text: section, bold: true })],
            spacing: { before: 150, after: 150 },
            borderBottom: {
              type: "single",
              color: "black",
              size: 6,
            },
          });

          const subsectionParagraphs = Object.entries(subsections).map(
            ([subsection, questions]) => {
              const subsectionParagraph = new Paragraph({
                children: [new TextRun({ text: subsection, bold: true })],
                spacing: { before: 150, after: 150 },
                borderBottom: {
                  type: "single",
                  color: "black",
                  size: 6,
                },
              });

              const questionAnswerParagraphs = questions.map((qa, index) => {
                const questionParagraph = new Paragraph({
                  children: [
                    new TextRun({
                      text: `Question ${index + 1}: ${qa.question}`,
                      bold: true,
                    }),
                  ],
                  spacing: { before: 10, after: 10 },
                });
                const answerParagraph = new Paragraph({
                  children: [
                    new TextRun({
                      text: `Answer: ${qa.answers || "Not answered"}`,
                    }),
                  ],
                  spacing: { before: 10, after: 10 },
                });
                return [questionParagraph, answerParagraph];
              });

              const flattenedQuestionAnswerParagraphs =
                questionAnswerParagraphs.flat();
              return [
                subsectionParagraph,
                ...flattenedQuestionAnswerParagraphs,
              ];
            }
          );

          const flattenedSubsectionParagraphs = subsectionParagraphs.flat();
          return [sectionParagraph, ...flattenedSubsectionParagraphs];
        }
      );

      const flattenedSectionsParagraphs = sectionsParagraphs.flat();

      const doc = new Document({
        sections: [
          {
            properties: {},
            children: flattenedSectionsParagraphs,
          },
        ],
      });
      generateDocx(doc);
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error("Error in downloading question list !");
      setLoading(false);
    }
  };

  const generateDocx = async (doc) => {
    try {
      const arrayBuffer = await Packer.toBlob(doc);
      if (!arrayBuffer) {
        throw new Error("Failed to generate document buffer");
      }
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      saveAs(blob, "Question list.docx");
      message.success("Question list downloaded successfully !");
    } catch (error) {
      console.error(error);
      message.error("Error in downloading question list !");
    }
  };

  return (
    <div>
      <p
        className="border border-[#5D5FEF] w-full flex justify-start bg-white text-[#5D5FEF] px-2 py-2 hover:bg-[#5D5FEF] text-base font-medium hover:text-white justify-self-end text-center cursor-pointer rounded-md mr-4"
        onClick={getAllReport}
      >
        <div className="flex gap-1">
          {loading ? (
            <>
              <LoadingOutlined />
              <span>Loading</span>
            </>
          ) : (
            <>
              <DownloadOutlined />
              <span>Result</span>
            </>
          )}
        </div>
      </p>
    </div>
  );
};

export default MasterScoreDownload;
