import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Result
      style={{ paddingTop: "8%" }}
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button size="large" type="primary">
            Back
          </Button>
        </Link>
      }
    />
  );
};

export default NotFoundPage;
