import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const SuperAdminAssessment = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        onClick={() => navigate(`/assessment?user_id=${props?.data?.user_id}`)}
        icon={<EditOutlined />}
        className="px-4"
      />
    </div>
  );
};

export default SuperAdminAssessment;
