export const Organisation_type = [
  "All",
  "Services",
  "Manufacturing",
  "Banks",
  "Banks/NBFC",
  "BFSI",
  "Insurance",
].map((value) => ({ value, label: value }));

export const DEPARTMENT_CHOICES = [
  { value: "All", label: "All" },
  { value: "Procurement", label: "Procurement" },
  { value: "Facilities", label: "Facilities" },
  { value: "SeniorLeadership", label: "Senior Leadership" },
  { value: "Production", label: "Production" },
  { value: "CSR", label: "CSR" },
  { value: "IT", label: "IT" },
  { value: "HR", label: "HR" },
  { value: "Audit", label: "Audit" },
  { value: "Marketing", label: "Marketing" },
  { value: "Finance", label: "Finance" },
  { value: "ProductDevelopment", label: "Product Development" },
  { value: "RetailBanking", label: "Retail Banking" },
  { value: "Loans", label: "Loans" },
  { value: "CustomerServices", label: "Customer Services" },
  { value: "Compliance", label: "Compliance" },
  { value: "Legal", label: "Legal" },
  { value: "Sustainability", label: "Sustainability" },
  { value: "CompanySecretary", label: "Company Secretary" },
  { value: "Sales", label: "Sales " },
  { value: "Operations", label: "Operations " },
];

export const regulations = [
  "BRSR",
  "CII",
  "IGBC",
  "LEED",
  "SDG",
  "TCFD",
  "CDP",
  "GRI",
  "SBT",
  "ESRS",
  "UNGC",
  "GoodPractice",
  "SwachhBharatMission",
  "SASB",
  "CentralBanks",
  "WBCSD",
  "WBA",
  "WHO",
  "GoodGovernancePractice",
  "MCA",
  "IFRS",
  "OECD",
  "ILO",
  "FATF",
  "UNEP",
  "CSRD",
  "UNEPSI",
  "UNGP",
  "UNCTAD",
];

export const AnswerType = [
  { value: "Yes/No", label: "Yes/No" },
  { value: "Yes/No/NA", label: "Yes/No/NA" },
  { value: "Range", label: "Range" },
];

export const regulation_choice = [
  "All",
  "BRSR",
  "CII",
  "IGBC",
  "LEED",
  "SDG",
  "TCFD",
  "CDP",
  "GRI",
  "SBT",
  "ESRS",
  "UNGC",
  "GoodPractice",
  "SwachhBharatMission",
  "SASB",
  "CentralBanks",
  "WBCSD",
  "WBA",
  "WHO",
  "GoodGovernancePractice",
  "MCA",
  "IFRS",
  "OECD",
  "ILO",
  "FATF",
  "UNEP",
  "CSRD",
  "UNEPSI",
  "UNGP",
  "UNCTAD",
]
  .sort()
  .map((value) => ({
    value,
    label:
      value === "SwachhBharatMission"
        ? "Swachh Bharat Mission"
        : value === "GoodPractice"
        ? "Good Practice"
        : value === "CentralBanks"
        ? "Central Banks"
        : value === "GoodGovernancePractice"
        ? "Good Governance Practice"
        : value,
  }));

export const environmentChoice = [
  { value: "SustainableSourcing", label: "Sustainable Sourcing" },
  { value: "EmissionsAndEnergy", label: "Emissions And Energy" },
  { value: "Waste", label: "Waste" },
  { value: "Biodiversity", label: "Biodiversity" },
  { value: "WaterAndSanitation", label: "Water And Sanitation" },
  {
    value: "AfforestationReforestationDeforestation",
    label: "Afforestation Reforestation Deforestation",
  },
  {
    value: "CleanMobilityAndCleanEnergy",
    label: "Clean Mobility And Clean Energy",
  },
  { value: "ReduceRecycleReuse", label: "Reduce Recycle Reuse" },
  { value: "PlasticManagement", label: "Plastic Management" },
];

export const socialChoice = [
  { value: "OccupationalSafety", label: "Occupational Safety" },
  { value: "HealthAndHygiene", label: "Health And Hygiene" },
  {
    value: "EmploymentBenefitsAndWorkCulture",
    label: "Employment Benefits And Work Culture",
  },
  {
    value: "EquityDiversityAndInclusion",
    label: "Equity Diversity And Inclusion",
  },
  {
    value: "EmployeeGrievanceRedressal",
    label: "Employee Grievance Redressal",
  },
  { value: "LearningAndDevelopment", label: "Learning And Development" },
  { value: "WorkLifeBalance", label: "Work Life Balance" },
  {
    value: "ProvidingEasierEmployability",
    label: "Providing Easier Employability",
  },
  { value: "Community", label: "Community" },
  { value: "Customer", label: "Customer" },
  { value: "SupplyChain", label: "Supply Chain" },
];

export const governanceChoice = [
  {
    value: "BoardStructureAndFunctioning",
    label: "Board Structure And Functioning",
  },
  { value: "BoardMeetings", label: "Conduct of Board Meetings" },
  {
    value: "BoardCommitteeMeetings",
    label: "Conduct of Board Committee Meetings",
  },
  {
    value: "ManagementCommitteeMeetingsAndMinutesRecording",
    label: "Management Committee Meetings And Minutes Recording",
  },
  {
    value: "ArmsLengthAndConflictOfInterest",
    label: "Arms Length And Conflict Of Interest",
  },
  {
    value: "StatutoryAuditAndFinancials",
    label: "Statutory Audit And Financials",
  },
  { value: "Policies", label: "Policies" },
  { value: "Processes", label: "Processes" },
  { value: "InternalAudit", label: "Internal Audit" },
  { value: "Risk", label: "Risk" },
  { value: "Compliance", label: "Compliance" },
  { value: "ResponsibleTech", label: "Responsible Tech" },
  {
    value: "TransparencyAndDisclosures",
    label: "Transparency And Disclosures",
  },
  { value: "EquityInPay", label: "Equity In Pay" },
  { value: "CustomerCentricity", label: "Customer Centricity" },
  { value: "MinorityShareholderRights", label: "Minority Shareholder Rights" },
  { value: "SustainabilityInAction", label: "Sustainability In Action" },
  { value: "Economic", label: "Economic" },
];

export const section_choice = [
  { value: "Environment", label: "Environment" },
  { value: "Social", label: "Social" },
  { value: "Governance", label: "Governance" },
];
