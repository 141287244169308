import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { ESG_TOKEN } from "../../Assets/Constant";

const ProtectedRoutes = () => {
  const accessToken = Cookies.get(ESG_TOKEN);
  const isAuthenticated = accessToken && accessToken !== "false";
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
