import React from "react";
import { Master_User, Super_User } from "../../Assets/Constant";
import CustomButton from "../../Custom/CustomButton";
import { useNavigate } from "react-router-dom";
import { Popconfirm, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const QuestionComponent = (props) => {
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const handleDecline = (question_id) => {
    props.handleDecline(question_id);
  };
  const handleDelete = (value) => {
    props.handleDelete(value);
  };
  const handleApprove = (value) => {
    props.handleApprove(value);
  };
  return (
    <div>
      {props.pendingData?.map((item, index) => (
        <div
          className="justify-center mt-5 border bg-white px-8 py-6 rounded-xl border-solid border-zinc-300 max-md:px-5"
          key={item?.id}
        >
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-9/12 max-md:w-full max-md:ml-0">
              <span className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-10">
                <div className="text-indigo-500 text-xl font-semibold max-md:max-w-full">
                  Question {index + 1}
                </div>
                <div className="text-black text-xl font-medium leading-6 mt-3 max-md:max-w-full">
                  {item?.question}
                </div>
                <div className="text-indigo-500 mt-2 text-xl font-semibold max-md:max-w-full">
                  Remarks
                </div>
                <div className="text-[#666666] text-sm mt-3 max-md:max-w-full">
                  {item?.remarks}
                </div>
                <div className="flex justify-start flex-wrap gap-6 mt-7">
                  <span className="flex flex-col">
                    <div className="text-black text-sm font-semibold whitespace-nowrap">
                      Section
                    </div>
                    <div className="text-stone-500 text-xs whitespace-nowrap mt-4">
                      {item?.section}
                    </div>
                  </span>
                  <span className="flex flex-col">
                    <div className="text-black text-sm font-semibold whitespace-nowrap">
                      Sub - Section
                    </div>
                    <div className="text-stone-500 text-xs mt-4">
                      {item?.sub_section.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </div>
                  </span>
                  <span className="flex flex-col ">
                    <div className="text-black text-sm font-semibold whitespace-nowrap">
                      Department
                    </div>
                    <div className="text-stone-500 text-xs mt-4">
                      {item?.department.join(",")}
                    </div>
                  </span>
                  <span className="flex  flex-col">
                    <div className="text-black text-sm font-semibold whitespace-nowrap">
                      Organisation
                    </div>
                    <div className="text-stone-500 text-xs mt-3.5">
                      {item?.organization_sector.join(",")}
                    </div>
                  </span>

                  {item?.created_by_first_name && (
                    <span className="flex flex-col ">
                      <div className="text-black text-sm font-semibold whitespace-nowrap">
                        Created by
                      </div>
                      <div className="text-stone-500 text-xs mt-3.5">
                        {item?.created_by_first_name}
                      </div>
                    </span>
                  )}
                  <span className="flex flex-col ">
                    <div className="text-black text-sm font-semibold whitespace-nowrap">
                      Regulation
                    </div>
                    <div className="text-stone-500 text-xs mt-3.5 cursor-pointer">
                      {item?.regulation.length > 5 ? (
                        <Tooltip
                          title={
                            item?.regulation ? item.regulation.join(", ") : ""
                          }
                        >
                          <span>
                            {item?.regulation
                              ? item.regulation.slice(0, 5).join(", ") + "..."
                              : ""}
                          </span>
                        </Tooltip>
                      ) : (
                        <span>{item?.regulation?.join(",")}</span>
                      )}
                    </div>
                  </span>
                </div>
              </span>
            </div>
            <div className=" w-3/12 h-full ml-5 max-md:w-full max-md:ml-0">
              {userRole === Super_User && (
                <div className="flex h-full justify-end items-end gap-4 mt-44 max-md:mt-10">
                  {props.checkIcon && (
                    <CustomButton
                      disabled={userRole === Master_User}
                      onClick={() => handleApprove(item?.id)}
                      className="border flex gap-2 items-center border-green-500 bg-green-500 text-white px-6 py-3  hover:bg-green-300 text-base font-medium "
                    >
                      {props.checkIcon}
                    </CustomButton>
                  )}
                  {props.declineIcon && (
                    <CustomButton
                      disabled={userRole === Master_User}
                      onClick={() => handleDecline(item?.id)}
                      className="border  flex gap-2 items-center border-red-500 bg-red-500 text-white px-6 py-3 hover:bg-red-300 text-base font-medium justify-self-end"
                    >
                      {props.declineIcon}
                    </CustomButton>
                  )}
                  <CustomButton
                    title="Edit"
                    disabled={userRole === Master_User}
                    onClick={() => navigate(`/createquestion?id=${item?.id}`)}
                    className="border border-[#5D5FEF] bg-[#5D5FEF] text-white px-6 py-2 hover:bg-[#7c7eec] text-base font-medium justify-self-end"
                  />
                  <Popconfirm
                    title="Delete the question"
                    description="Are you sure to delete this question?"
                    onConfirm={() => handleDelete(item?.id)}
                    onCancel={props.onCancel}
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                  >
                    <CustomButton
                      title="Delete"
                      disabled={userRole === Master_User}
                      className="border border-[#5D5FEF] bg-white text-[#5D5FEF] px-6 py-2 hover:bg-[#5D5FEF] text-base font-medium hover:text-white justify-self-end"
                    />
                  </Popconfirm>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionComponent;
