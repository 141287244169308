import React, { useEffect, useState } from "react";
import CustomSectionChart from "../../Custom/CustomSectionChart";
import ApiCallService from "../../Services";
import { Button, message } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import api from "../../Composables/api";
import FileSaver from "file-saver";
import { Master_User } from "../../Assets/Constant";
import CustomHeader from "../../Custom/CustomHeader";

const ReportDetails = () => {
  const [section, setSection] = useState([]);
  const [sectionValue, setSectionValue] = useState([]);
  const [socSection, setSocSection] = useState([]);
  const [socSectionValue, setSocSectionValue] = useState([]);
  const [govSection, setGovSection] = useState([]);
  const [govSectionValue, setGovSectionValue] = useState([]);
  const [mainSection, setMainSection] = useState([]);
  const [mainSectionValue, setMainSectionValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const apiCallService = new ApiCallService();

  const { reportId } = useParams();

  const getFinalSectionScore = async () => {
    const response = await apiCallService.getSectionWiseReport(
      `/calculate_final_score_with_subsections/?user_id=${reportId || ""}`
    );
    const data = response?.data[0]?.section_subsection_wise_scores;
    setDisable(response?.data[0]?.final_submission);
    const sectionData = data?.Environment;
    const environmentArray = [];
    for (const [key, value] of Object.entries(sectionData)) {
      environmentArray.push({ key, value });
    }
    const keysArray = environmentArray.map((item) => item?.key);
    const valuesArray = environmentArray.map((item) => Math.round(item?.value));
    setSection(keysArray);
    setSectionValue(valuesArray);
    const socSectionData = data?.Social;
    const socialArray = [];
    for (const [key, value] of Object.entries(socSectionData)) {
      socialArray.push({ key, value });
    }
    const keysSocArray = socialArray.map((item) => item?.key);
    const valuesSocArray = socialArray.map((item) => Math.round(item?.value));
    setSocSection(keysSocArray);
    setSocSectionValue(valuesSocArray);
    const govSectionData = data?.Governance;
    const govArray = [];
    for (const [key, value] of Object.entries(govSectionData)) {
      govArray.push({ key, value });
    }
    const keysGovArray = govArray.map((item) => item?.key);
    const valuesGovArray = govArray.map((item) => Math.round(item?.value));
    setGovSection(keysGovArray);
    setGovSectionValue(valuesGovArray);
    const sections = ["Environment", "Social", "Governance", "Total"];
    setMainSection(sections);
    const sectionValue = [];
    sectionValue[0] = Math.round(data?.Environment?.Total);
    sectionValue[1] = Math.round(data?.Social?.Total);
    sectionValue[2] = Math.round(data?.Governance?.Total);
    sectionValue[3] = Math.round(response?.data[0]?.total_score?.overall);
    setMainSectionValue(sectionValue);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await api.get("/generate_chart/", {
        responseType: "blob",
      });
      FileSaver.saveAs(response.data, "Score card.pdf");
      message.success("Score card downloaded successfully !");
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error("Failed to download score card.");
      setLoading(false);
    }
  };

  useEffect(() => {
    getFinalSectionScore();
  }, []);

  const userRole = localStorage.getItem("userRole");

  return (
    <>
      <CustomHeader />
      <div className="p-6">
        {userRole === Master_User && (
          <Button
            onClick={handleDownload}
            disabled={!disable}
            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
          >
            {loading ? "Loading ..." : "Download Score Card"}
          </Button>
        )}
        <div className="grid lg:grid-cols-1 mt-5 gap-10 md:grid-cols-1 sm:grid-cols-1 grid-cols-1">
          <div className="bg-white flex flex-col rounded-md gap-5 p-5">
            <h3 className="">ESG Scores</h3>
            <CustomSectionChart
              section={mainSection}
              subSection={mainSectionValue}
            />
          </div>
          <div className="bg-white flex flex-col rounded-md gap-5 p-5">
            <h3 className="">Environment Scores</h3>
            <CustomSectionChart section={section} subSection={sectionValue} />
          </div>
          <div className="bg-white flex flex-col rounded-md gap-5 p-5">
            <h3 className="">Social Scores</h3>
            <CustomSectionChart
              section={socSection}
              subSection={socSectionValue}
            />
          </div>
          <div className="bg-white flex flex-col rounded-md gap-5 p-5">
            <h3 className="">Governance Scores</h3>
            <CustomSectionChart
              section={govSection}
              subSection={govSectionValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportDetails;
