import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { Empty, Popconfirm, Spin, message } from "antd";
import Cookies from "js-cookie";
import {
  ESG_TOKEN,
  Master_User,
  Sub_User,
  Super_User,
} from "../../Assets/Constant";
import CustomButton from "../../Custom/CustomButton";
import CustomHeader from "../../Custom/CustomHeader";
import CustomModal from "../../Custom/CustomMessageModal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/celebrate_icon.svg";
import Logo from "../../Images/GreenWorldLog.png";
import CustomTab from "../../Custom/CustomTab";
import AssessmentQuestion from "../../components/AssessmentQuestion";
import api from "../../Composables/api";

const Assessment = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSocialIndex, setActiveSocialIndex] = useState(0);
  const [activeGovernanceIndex, setActiveGovernanceIndex] = useState(0);
  const [data, setData] = useState([]);
  const [uniqueSubSections, setUniqueSubSections] = useState([]);
  const [uniqueSocialSubSections, setUniqueSocialSubSections] = useState([]);
  const [uniqueGovernanceSubSections, setUniqueGovernanceSubSections] =
    useState([]);
  const [showRemarks, setShowRemarks] = useState(null);
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [currentTab, setCurrentTab] = useState("1");
  const [showUnanswered, setShowUnanswered] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("user_id");

  const plainOptions = ["Yes", "No", "NA"];
  const plainOption = ["Yes", "No"];
  const RangeOption = ["0-25", "26-50", "51-75", "76-100"];

  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/users");
    setOpen(false);
  };

  const closeModal = (value) => {
    navigate("/users");
    setOpen(value);
  };

  const handleChange = async (
    questionId,
    { target: { value } },
    dependents
  ) => {
    setValues((prevValues) => ({
      ...prevValues,
      [questionId]: value,
    }));
    try {
      const response = await api.post("/answer/", {
        question_id: questionId,
        response: value,
        ...(id && { master_user_id: id }),
      });
      getAllQuestion();
      message.success("Answer saved");
      setValues({ [questionId]: value });
    } catch (error) {
      message.error(error?.response?.data?.error);
    }
  };

  const handlePrevious = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleSocialPrevious = () => {
    if (activeSocialIndex > 0) {
      setActiveSocialIndex(activeSocialIndex - 1);
    } else if (
      activeSocialIndex ===
      (uniqueSocialSubSections.length || "1") - 1
    ) {
      setCurrentTab("1");
    } else {
      setCurrentTab("1");
    }
  };

  const handleGovernancePrevious = () => {
    if (activeGovernanceIndex > 0) {
      setActiveGovernanceIndex(activeGovernanceIndex - 1);
    } else if (
      activeGovernanceIndex ===
      (uniqueGovernanceSubSections.length || "1") - 1
    ) {
      setCurrentTab("2");
    } else {
      setCurrentTab("2");
    }
  };

  const handleGovernanceNext = async () => {
    if (
      activeGovernanceIndex <
      (uniqueGovernanceSubSections.length || "1") - 1
    ) {
      setActiveGovernanceIndex(activeGovernanceIndex + 1);
    } else if (
      activeGovernanceIndex ===
      (uniqueGovernanceSubSections.length || "1") - 1
    ) {
    }
  };

  const handleAnswerSumit = async () => {
    if (userRole === Master_User) {
      try {
        const response = await api.post("/final_submission/");
        setOpen(true);
      } catch (error) {
        console.log(error);
      }
    } else if (userRole === Super_User) {
      setOpen(true);
    } else {
      navigate("/thanks");
    }
  };

  const handleNext = async () => {
    if (activeIndex < uniqueSubSections.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else if (activeIndex === uniqueSubSections.length - 1) {
      const currentIndex = parseInt(currentTab, 10);
      const nextIndex = (currentIndex % items.length) + 1;
      setCurrentTab(nextIndex.toString());
    }
  };

  const handleSocialNext = async () => {
    if (activeSocialIndex < (uniqueSocialSubSections.length || "1") - 1) {
      setActiveSocialIndex(activeSocialIndex + 1);
    } else if (
      activeSocialIndex ===
      (uniqueSocialSubSections.length || "1") - 1
    ) {
      const currentIndex = parseInt(currentTab, 10);
      const nextIndex = (currentIndex % items.length) + 1;
      setCurrentTab(nextIndex.toString());
    }
  };
  const getAllQuestion = async () => {
    try {
      let response;
      if (showUnanswered) {
        response = await api.get(`/unanswered_questions/?user_id=${id}`);
        response.data.questions = response.data.unanswered_questions;
        setSubmitDisabled(response.data.questions.length === 0 ? false : true);
      } else {
        response = await api.get(`/filter-question/?user_id=${id}`);
        setSubmitDisabled(
          response?.data?.total_questions === response?.data?.total_answers
            ? false
            : true
        );
      }
      setData(response?.data);
      setDisable(response?.data?.final_submission);
      const subSections = Array.from(
        new Set(
          response?.data?.questions
            ?.filter((items) => items?.section === "Environment")
            ?.map((item) => item.sub_section)
        )
      );
      const subSocialSections = Array.from(
        new Set(
          response?.data?.questions
            ?.filter((items) => items?.section === "Social")
            ?.map((item) => item.sub_section)
        )
      );
      const subGovernanceSections = Array.from(
        new Set(
          response?.data?.questions
            ?.filter((items) => items?.section === "Governance")
            ?.map((item) => item.sub_section)
        )
      );
      setUniqueSubSections(subSections);
      setUniqueSocialSubSections(subSocialSections);
      setUniqueGovernanceSubSections(subGovernanceSections);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllQuestion();
  }, [showUnanswered]);

  const openRemarks = (id) => {
    setShowRemarks((prevId) => (prevId === id ? null : id));
  };

  const handleLogout = () => {
    Cookies.remove(ESG_TOKEN, { path: "/" });
    localStorage.clear();
    window.location.href = "/";
  };

  const tabChange = (key) => {
    setCurrentTab(key);
  };

  const items = [
    {
      key: "1",
      label: "Environment",
      disabled: uniqueSubSections.length === 0,
      children: (
        <>
          {uniqueSubSections.length === 0 ? (
            <div className="flex justify-center items-center h-[80%]">
              <Empty />
            </div>
          ) : (
            <div className=" md:px-8 sm:px-0 px-0 grid md:grid-cols-3 sm:grid-cols-1 grid-cols-1 md:gap-7 gap-1">
              <div className="flex flex-col gap-6">
                {uniqueSubSections.map((subSection, index) => (
                  <button
                    key={index}
                    className={`p-4 text-base text-left rounded-2xl ${
                      activeIndex === index
                        ? "bg-[#5D5FEF] border border-[#5D5FEF] text-white"
                        : "bg-[#fff]"
                    }`}
                    onClick={() => setActiveIndex(index)}
                  >
                    {subSection.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </button>
                ))}
              </div>
              <div className="w-full col-span-2">
                <AssessmentQuestion
                  activeIndex={activeIndex}
                  data={data?.questions?.filter(
                    (items) => items?.section === "Environment"
                  )}
                  uniqueSubSections={uniqueSubSections}
                  plainOption={plainOption}
                  plainOptions={plainOptions}
                  rangeOptions={RangeOption}
                  disabled={disable}
                  onChange={handleChange}
                  values={values}
                  showRemarks={showRemarks}
                />

                <div className="flex justify-end gap-5 mt-5">
                  <CustomButton
                    title="Previous Section"
                    className="bg-white px-4 py-2 text-[#5D5FEF] border-solid border-[#5D5FEF]"
                    onClick={handlePrevious}
                  />
                  <CustomButton
                    title={
                      activeIndex === uniqueSubSections.length - 1
                        ? "Next Tab"
                        : "Next Section"
                    }
                    className={
                      "bg-[#5D5FEF] px-4 py-2 text-white border-solid border-[#5D5FEF]"
                    }
                    onClick={handleNext}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Social",
      disabled: uniqueSocialSubSections.length === 0,
      children: (
        <>
          {uniqueSocialSubSections.length === 0 ? (
            <div className="flex justify-center items-center h-[80%]">
              <Empty />
            </div>
          ) : (
            <div className=" md:px-8 sm:px-0 px-0  grid md:grid-cols-3 sm:grid-cols-1 grid-cols-1 md:gap-7 gap-1">
              <div className="flex flex-col w-full gap-6">
                {uniqueSocialSubSections.map((subSection, index) => (
                  <button
                    key={index}
                    className={`p-4 text-base text-left rounded-2xl ${
                      activeSocialIndex === index
                        ? "bg-[#5D5FEF] border border-[#5D5FEF] text-white"
                        : "bg-[#fff]"
                    }`}
                    onClick={() => setActiveSocialIndex(index)}
                  >
                    {subSection.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </button>
                ))}
              </div>
              <div className="w-full col-span-2">
                <AssessmentQuestion
                  activeIndex={activeSocialIndex}
                  data={data?.questions?.filter(
                    (items) => items?.section === "Social"
                  )}
                  uniqueSubSections={uniqueSocialSubSections}
                  plainOption={plainOption}
                  plainOptions={plainOptions}
                  rangeOptions={RangeOption}
                  disabled={disable}
                  onChange={handleChange}
                  values={values}
                  showRemarks={showRemarks}
                />

                <div className="flex justify-end gap-5 mt-5">
                  <CustomButton
                    title="Previous Section"
                    className="bg-white px-4 py-2 text-[#5D5FEF] border-solid border-[#5D5FEF]"
                    onClick={handleSocialPrevious}
                  />
                  <CustomButton
                    title={
                      activeSocialIndex === uniqueSocialSubSections.length - 1
                        ? "Next Tab"
                        : "Next Section"
                    }
                    className={
                      "bg-[#5D5FEF] px-4 py-2 text-white border-solid border-[#5D5FEF]"
                    }
                    onClick={handleSocialNext}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Governance",
      disabled: uniqueGovernanceSubSections.length === 0,
      children: (
        <>
          {uniqueGovernanceSubSections.length === 0 ? (
            <div className="flex justify-center items-center h-[80%]">
              <Empty />
            </div>
          ) : (
            <div className=" md:px-8 sm:px-0 px-0  grid md:grid-cols-3 sm:grid-cols-1 grid-cols-1 md:gap-7 gap-1">
              <div className="flex flex-col gap-6 w-full">
                {uniqueGovernanceSubSections.map((subSection, index) => (
                  <button
                    key={index}
                    className={`p-4 text-base text-left rounded-2xl ${
                      activeGovernanceIndex === index
                        ? "bg-[#5D5FEF] border border-[#5D5FEF] text-white"
                        : "bg-[#fff]"
                    }`}
                    onClick={() => setActiveGovernanceIndex(index)}
                  >
                    {subSection.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </button>
                ))}
              </div>
              <div className="w-full col-span-2">
                <AssessmentQuestion
                  activeIndex={activeGovernanceIndex}
                  data={data?.questions?.filter(
                    (items) => items?.section === "Governance"
                  )}
                  uniqueSubSections={uniqueGovernanceSubSections}
                  plainOption={plainOption}
                  plainOptions={plainOptions}
                  disabled={disable}
                  onChange={handleChange}
                  values={values}
                  showRemarks={showRemarks}
                />
                <div className="flex justify-end gap-5 mt-5">
                  <CustomButton
                    title="Previous Section"
                    className="bg-white px-4 py-2 text-[#5D5FEF] border-solid border-[#5D5FEF]"
                    onClick={handleGovernancePrevious}
                  />
                  <CustomButton
                    title={"Next Section"}
                    className="bg-[#5D5FEF] px-4 py-2 text-white border-solid border-[#5D5FEF]"
                    onClick={handleGovernanceNext}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <CustomModal
        open={open}
        handleClose={handleClose}
        children={
          <SuccessMessage
            img={Tick}
            message={`Thank you for completing the survey
            `}
            desc="You have successfully completed the survey you’ll be getting your scorecard in your mail"
            colors="bg-[#5d5FEF]"
            modalClose={closeModal}
          />
        }
      />
      <CustomHeader logo={Logo}>
        <Popconfirm
          title="Logout"
          description="Are you sure to you want to logout?"
          onConfirm={handleLogout}
          okText="Yes"
          cancelText="No"
        >
          <CustomButton title="Logout">
            <LogoutOutlined />
          </CustomButton>
        </Popconfirm>
      </CustomHeader>

      <div className="bg-[#F2F2FF] p-5 min-h-screen">
        <div className="flex justify-between">
          <h2 className="text-[#5D5FEF]">Assessment </h2>
          <div className="flex gap-5">
            <CustomButton
              onClick={() => {
                setShowUnanswered(!showUnanswered);
              }}
            >
              {showUnanswered
                ? "Answered"
                : (data?.total_unanswered_questions || " ") +
                  " " +
                  "Unanswered"}
            </CustomButton>
            {userRole === Sub_User || userRole === Super_User ? (
              <CustomButton
                title="Submit"
                className="px-6 bg-[#5D5FEF] py-2 text-white border-solid border-[#5D5FEF]"
                onClick={handleAnswerSumit}
              />
            ) : (
              <CustomButton
                title="Submit"
                disabled={submitDisabled || disable}
                className={
                  submitDisabled || disable
                    ? "bg-[#8e90ed] px-6 py-2 cursor-no-drop text-white border-solid border-[#8e90ed]"
                    : "bg-[#5D5FEF] px-6 py-2 text-white border-solid border-[#5D5FEF]"
                }
                onClick={handleAnswerSumit}
              />
            )}
          </div>
        </div>
        <div>
          {showUnanswered ? (
            <CustomTab
              items={items}
              activeKey={currentTab}
              onChange={tabChange}
            />
          ) : (
            <div className="flex flex-col gap-6">
              <CustomTab
                items={items}
                activeKey={currentTab}
                onChange={tabChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Assessment;
