import React, { useEffect, useState } from "react";
import api from "../../Composables/api";
import { DownloadOutlined } from "@ant-design/icons";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

const generateDocx = (data, score, userRegulations) => {
  const consolidatedText =
    "This is a consolidated report based on a survey conducted, providing an integrated analysis of gathered data. It offers a comprehensive overview of the survey results, aiding in informed decision-making. Key insights and trends are highlighted to facilitate a deeper understanding of the findings.";
  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Green World",
                bold: true,
                size: 32,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Your score: ",
              }),
              new TextRun({
                text: Math.round(score?.overall)?.toString(),
                bold: true,
                size: 24,
              }),
            ],
          }),
          new Paragraph({
            text: consolidatedText,
          }),
          new Paragraph({
            text: `User Regulations: ${userRegulations?.join(", ")}`,
          }),
          ...Object?.keys(data || {})
            ?.map((item) => [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item}: ${score[item]?.score || ""}`,
                    bold: true,
                  }),
                ],
              }),
              ...Object?.keys(data[item] || {})
                ?.filter((subItem) => data[item][subItem]?.q > 0)
                ?.map((subItem) => [
                  new Paragraph({
                    text: `${subItem?.replace(
                      /(?<!^)([a-z])([A-Z])/g,
                      "$1 $2"
                    )}: ${data[item][subItem]?.score ?? ""}`,
                  }),
                  ...["yes", "no", "na", "range"]
                    ?.map((answerType) =>
                      (data[item][subItem]?.recommendations?.[answerType] || [])
                        ?.map((answer) =>
                          answer
                            ? new Paragraph({
                                text: `• ${answer?.recommendation}`,
                              })
                            : null
                        )
                        ?.filter(Boolean)
                    )
                    ?.flat(),
                ]),
            ])
            ?.flat(2),
        ],
      },
    ],
  });

  Packer.toBlob(doc)?.then((blob) => {
    saveAs(blob, "consolidated_report.docx");
  });
};

const PdfDownload = (props) => {
  const [data, setData] = useState(null);
  const getAllReport = async () => {
    try {
      const response = await api.get(
        `/reports/?user_id=${props?.data?.user_id}`
      );
      setData(response?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props?.data?.user_id) {
      getAllReport();
    }
  }, [props?.data?.user_id]);

  return (
    <div>
      <p
        className="border border-[#5D5FEF] w-full bg-white text-[#5D5FEF] px-2 py-2 hover:bg-[#5D5FEF] text-base font-medium hover:text-white justify-start cursor-pointer block  rounded-md mr-4"
        onClick={() =>
          generateDocx(
            data?.section_subsection_wise_scores,
            data?.total_score,
            data?.user_regulation
          )
        }
      >
        <>
          <DownloadOutlined /> Report
        </>
      </p>
    </div>
  );
};

export default PdfDownload;
