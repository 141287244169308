import CustomButton from "../CustomButton";

export function SuccessMessage(props) {
  const handleClose = () => {
    props.modalClose(false);
  };

  return (
    <div className="flex flex-col gap-10 justify-center items-center">
      <div className="flex flex-col gap-3 justify-center items-center">
        <img src={props.img} alt="Tick" className="w-1/2" />
        <div className="flex flex-col gap-2">
          <div className="text-center text-xl text-wrap max-w-80 font-medium text-black">
            {props.message}
          </div>
          <div className="text-center text-sm text-wrap max-w-80 font-normal text-[#7E7E7E]">
            {props.desc}
          </div>
        </div>
      </div>
      <div className="w-full">
        <CustomButton
          title="OK"
          className={`border-none py-2 text-base font-medium w-full  text-white ${
            props.colors && props.colors
          }`}
          onClick={handleClose}
        />
      </div>
    </div>
  );
}
