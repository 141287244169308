import React, { useState } from "react";
import api from "../../Composables/api";
import CustomModal from "../../Custom/CustomMessageModal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/celebrate_icon.svg";
import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import PdfDownload from "../PdfDownload";
import MasterScoreDownload from "../MasterScoreDownload";
import { Popover } from "antd";

const PublishDocument = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const closeModal = (value) => {
    setOpen(value);
  };

  const handlePublish = async (text) => {
    setLoading(true);
    try {
      await api.post("/update-publication-status/", {
        user_id: text?.user_id,
        publish: true,
      });
      setLoading(false);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const content = (
    <div>
      <PdfDownload data={props.data} />
      <MasterScoreDownload data={props.data} />
      {/* {!props?.data?.publish && ( */}
      <p
        onClick={() => handlePublish(props.data)}
        className="border border-[#5D5FEF] bg-white w-full text-[#5D5FEF] px-4 py-2 hover:bg-[#5D5FEF] text-base font-medium hover:text-white justify-start cursor-pointer block  rounded-md mr-4"
      >
        {loading ? (
          <div className="flex gap-1 text-nowrap">
            <LoadingOutlined /> Loading...
          </div>
        ) : props?.data?.publish ? (
          "Published report"
        ) : (
          "Publish report"
        )}
      </p>
      {/* )} */}
    </div>
  );

  return (
    <div>
      <CustomModal
        open={open}
        handleClose={handleClose}
        children={
          <SuccessMessage
            img={Tick}
            message={`Report Published Successfully`}
            desc={`Thank you for publishing the report. This user will receive an email containing the report shortly. In the meantime, he can download the report from his screen.`}
            colors="bg-[#5d5FEF]"
            modalClose={closeModal}
          />
        }
      />

      <Popover content={content} title="Actions">
        <MoreOutlined />
      </Popover>
    </div>
  );
};

export default PublishDocument;
