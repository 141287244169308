import React, { useEffect, useState } from "react";
import CustomButton from "../../Custom/CustomButton";
import api from "../../Composables/api";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import CustomModal from "../../Custom/CustomMessageModal";
import { SuccessMessage } from "../../Custom/SuccessMessage";
import Tick from "../../Images/Tick.svg";
import Cross from "../../Images/Cross.svg";
import Input from "../../Custom/LoginInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Master_User, SECTION_CHOICE, Super_User } from "../../Assets/Constant";
import CustomHeader from "../../Custom/CustomHeader";
import DropDown from "../../Custom/CustomDropDown";
import { countries, Organisation_type } from "../Register/Assets";
import { regulation_choice } from "../CreateQuestion/Assets";
import AddSubUser from "../../components/AddSubUser";
import { message } from "antd";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .required("Email is required"),
  valid_till: Yup.string().required("Valid till is required"),
  regulation: Yup.array()
    .min(1, "Regulation is required")
    .required("Regulation is required"),
  company_name: Yup.string().required("Company name is required"),
  organization_based: Yup.string().required("Organization based is required"),
  organization_sector: Yup.array()
    .min(1, "Organization sector is required")
    .required("Organization sector is required"),
  no_of_employees: Yup.string().required("No of employees required"),
  section: Yup.array()
    .min(1, "Section is required")
    .required("Section is required"),
});

const UserDetailsApprove = () => {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [approveMsg, setApproveMsg] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showValid, setShowValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const { userId } = useParams();

  const navigate = useNavigate();

  const No_Of_Employees = [
    { value: "0-10", label: "0-10" },
    { value: "10-25", label: "10-25" },
    { value: "25-50", label: "25-50" },
    { value: "50-100", label: "50-100" },
    { value: "100+", label: "100+" },
  ];

  const handleClose = () => {
    setOpen(false);
    navigate("/users");
  };

  const closeModal = (value) => {
    setOpen(value);
    navigate("/users");
  };

  const closeModal1 = () => {
    setOpen2(false);
  };

  const handleClosed = () => {
    setOpen2(false);
  };
  const userRole = localStorage.getItem("userRole");
  const getUserDetails = async () => {
    try {
      const response = await api.get(`/user/?user_id=${userId}`);
      setData(response?.data);
      if (response?.data?.status === "PENDING" && userRole === Super_User) {
        setShowButton(true);
      }
      if (response?.data?.status === "APPROVED" && userRole === Super_User) {
        setShowValid(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    role: data?.role || "",
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    valid_till: data?.valid_till || "",
    regulation: data?.regulation || [],
    organization_sector: data?.organization_sector || [],
    action: "approve",
    organization_based: data?.organization_based || "",
    company_name: data?.company_name || "",
    no_of_employees: data?.no_of_employees || "",
    section: data?.section || [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        values.status = "APPROVED";
        await api.put(`/user/?user_id=${userId}`, values);
        setOpen(true);
        setLoading(false);
        setApproveMsg("User updated successfully!");
      } catch (err) {
        setLoading(false);
        setOpen2(true);
        if (err?.response?.status === 400 && err?.response?.data) {
          if (Array.isArray(err.response.data)) {
            const errorMessage = Object.values(err.response.data)[0][0];
            setErrMsg(errorMessage);
          } else {
            setErrMsg(Object.values(err?.response?.data));
          }
        } else {
          setErrMsg(err?.response?.data?.message);
        }
      }
    },
  });

  const handleExtendDate = async () => {
    try {
      setLoading(true);
      const formData = {
        new_expiry_date: formik.values.valid_till,
        user_id: userId,
        action: "approve",
      };
      await api.put("/approve-user/", formData);
      message.success("Date Extended successfully !");
      navigate("/users");
      setLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setLoading(false);
      setOpen2(true);
      setErrMsg(err?.response?.data?.message);
    }
  };

  const handleDecline = async () => {
    try {
      setLoading(true);
      await api.put("/approve-user/", {
        user_id: userId,
        action: "decline",
      });
      setApproveMsg("User declined successfully !");
      setLoading(false);
      setOpen(true);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setLoading(false);
      setOpen2(true);
      setErrMsg(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <CustomHeader />
      <CustomModal
        open={open}
        handleClose={handleClose}
        children={
          <SuccessMessage
            img={Tick}
            message={approveMsg}
            desc=""
            colors="bg-[#32D52B]"
            modalClose={closeModal}
          />
        }
      />
      <CustomModal
        open={open2}
        handleClose={handleClosed}
        children={
          <SuccessMessage
            img={Cross}
            message={errMsg}
            desc=""
            colors="bg-[#F73232]"
            modalClose={closeModal1}
          />
        }
      />
      <div className="flex justify-center p-5">
        <div className="flex flex-col  items-stretch w-[71%] ml-5 max-md:w-full max-md:ml-0">
          <div className="items-stretch shadow-sm bg-stone-50 flex flex-col w-full my-auto pb-7 max-md:max-w-full max-md:mt-10">
            <span className="text-white text-2xl font-semibold leading-7 bg-indigo-500 justify-center pl-7 pr-16 py-6 items-start max-md:max-w-full max-md:px-5">
              Green world request awaiting for approval
            </span>

            <div className=" text-sm leading-5 self-stretch max-md:max-w-full p-3">
              <span className="">
                You have been assigned as an approver for this Green world
                request, please use the link at the bottom if this Email to be
                taken to Green world dashboard and use the Approve or Reject
                action from the drop down list for Ram Bros & Co Requester’s
                request to use access profile.
              </span>
            </div>
            {userRole === Super_User ? (
              <span className="flex w-[830px] max-w-full flex-col md:p-5 p-2 mt-7 self-start items-start">
                <div
                  // onSubmit={formik.handleSubmit}
                  className="w-full mt-8 flex flex-col gap-5"
                >
                  <div className="grid grid-col-1 md:grid-cols-2 gap-x-4 gap-y-2">
                    <Input
                      type="text"
                      placeholder="First Name"
                      title="First Name"
                      className="w-100"
                      name="first_name"
                      id="first_name"
                      readonly={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      required
                      value={formik?.values?.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.first_name && formik.errors?.first_name
                      }
                    />
                    <Input
                      type="text"
                      placeholder="Last Name"
                      title="Last Name"
                      className="w-100"
                      name="last_name"
                      id="last_name"
                      readonly={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      required
                      value={formik?.values?.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched?.last_name && formik.errors?.last_name
                      }
                    />
                    <Input
                      type="text"
                      placeholder="Company name"
                      title="Company name"
                      className="w-100"
                      name="company_name"
                      readonly={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      id="company_name"
                      required
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.company_name &&
                        formik.errors.company_name
                      }
                    />
                    <Input
                      type="email"
                      placeholder="Email"
                      title="Email"
                      className="w-100"
                      name="email"
                      id="email"
                      readonly={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      required
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && formik.errors.email}
                    />
                    <DropDown
                      label="What sector is your organisation in?"
                      placeholder="Select Organization"
                      id="organization_sector"
                      name="organization_sector"
                      className="w-100 py-2 rounded-xl"
                      disabled={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      options={Organisation_type}
                      multiple
                      value={formik.values.organization_sector}
                      onChange={(value) =>
                        formik.setFieldValue("organization_sector", value)
                      }
                      onBlur={() =>
                        formik.setFieldTouched("organization_sector")
                      }
                      error={
                        formik.touched.organization_sector &&
                        formik.errors.organization_sector
                      }
                    />
                    <DropDown
                      label="Where is your organisation based?"
                      placeholder="Organization based"
                      id="organization_based"
                      name="organization_based"
                      className="w-100 py-2 rounded-xl"
                      disabled={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      options={countries}
                      value={formik.values.organization_based}
                      onChange={(value) =>
                        formik.setFieldValue("organization_based", value)
                      }
                      onBlur={() =>
                        formik.setFieldTouched("organization_based")
                      }
                      error={
                        formik.touched.organization_based &&
                        formik.errors.organization_based
                      }
                    />
                    <DropDown
                      label="Select number of employees"
                      placeholder="No of employees"
                      id="no_of_employees"
                      name="no_of_employees"
                      className="w-100 py-2 rounded-xl"
                      disabled={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      options={No_Of_Employees}
                      value={formik.values.no_of_employees}
                      onChange={(value) =>
                        formik.setFieldValue("no_of_employees", value)
                      }
                      onBlur={() => formik.setFieldTouched("no_of_employees")}
                      error={
                        formik.touched.no_of_employees &&
                        formik.errors.no_of_employees
                      }
                    />
                    <DropDown
                      label="Regulations"
                      placeholder="Regulations"
                      multiple
                      id="regulation"
                      name="regulation"
                      className="w-100 py-2 rounded-xl"
                      options={regulation_choice}
                      disabled={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      value={formik?.values?.regulation}
                      onChange={(value) =>
                        formik.setFieldValue("regulation", value)
                      }
                      onBlur={() => formik.setFieldTouched("regulation")}
                      error={
                        formik.touched?.regulation && formik.errors?.regulation
                      }
                    />

                    <DropDown
                      label="Select Section"
                      placeholder="Select Section"
                      multiple
                      id="section"
                      name="section"
                      className="w-100 py-2 rounded-xl"
                      options={SECTION_CHOICE}
                      disabled={
                        data?.status !== "PENDING" && userRole !== Master_User
                      }
                      value={formik?.values?.section}
                      onChange={(value) =>
                        formik.setFieldValue("section", value)
                      }
                      onBlur={() => formik.setFieldTouched("section")}
                      error={
                        formik.touched?.user?.section &&
                        formik.errors?.user?.section
                      }
                    />
                    <Input
                      type="date"
                      placeholder="Licence Valid till"
                      title="Licence Valid till"
                      className="w-100"
                      name="valid_till"
                      id="valid_till"
                      required
                      min={today}
                      value={formik?.values?.valid_till}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.valid_till && formik.errors.valid_till
                      }
                    />
                  </div>

                  <div className="flex gap-6 justify-end items-center">
                    {showButton && (
                      <div className="items-stretch flex gap-4 mt-12 max-md:mt-10">
                        <CustomButton
                          title="Decline"
                          onClick={handleDecline}
                          className="bg-white border px-4 text-base hover:bg-purple-100 border-[#5D5FEF] text-[#5D5FEF]"
                        />
                        <CustomButton
                          title={loading ? "Loading .." : "Approve"}
                          onClick={formik.handleSubmit}
                          className="px-4 text-base bg-[#5D5FEF] text-white hover:bg-[#686ad0] border-[#686ad0]"
                        />
                      </div>
                    )}
                    {showValid && (
                      <CustomButton
                        title={
                          loading
                            ? "Loading .."
                            : userRole === Super_User
                            ? "Extend Date"
                            : "Submit"
                        }
                        onClick={handleExtendDate}
                        className="px-4 text-base bg-[#5D5FEF] mt-5 text-white hover:bg-[#686ad0] border-[#686ad0]"
                      />
                    )}
                  </div>
                </div>
              </span>
            ) : (
              <AddSubUser subUserData={data} userId={userId} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsApprove;
