import React from "react";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";

const onChange = (pagination, filters, sorter, extra) => {};
const CustomTable = (props) => {
  const navigate = useNavigate();
  const onRowClick = (record) => {
    props.url &&
      navigate(`${props.url}/${record?.id ? record?.id : record?.user_id}`);
  };

  return (
    <>
      <Table
        columns={props.columnData}
        onRow={(record, rowIndex) => ({
          onClick: () => onRowClick(record),
          className: "hover:cursor-pointer",
        })}
        dataSource={props?.data?.map((item) => ({
          ...item,
          key: item?.id,
        }))}
        onChange={onChange}
        scroll={{ x: props.scrollWidth }}
        pagination={{
          pageSize: props.pageSize,
          total: props.totalCount,
          onChange: (page) => {},
        }}
      />
    </>
  );
};
export default CustomTable;
