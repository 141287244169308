export const consolidatorColumn = [
  {
    title: "First Name",
    dataIndex: "first_name",
    width: 140,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    width: 140,
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 160,
  },
  {
    title: "Regulation",
    dataIndex: `regulation`,
    width: 160,
    render: (record, text) => <>{record?.join(",")}</>,
  },
];
