import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function CustomDropDown(props) {
  // Destructuring props for easier access
  const {
    options,
    label,
    id,
    value,
    onChange,
    onBlur,
    required,
    readonly,
    className,
    placeholder,
    styleClassName,
    error,
    optionPara,
    disabled,
    dropdownLabel,
    colors,
    multiple,
    ...otherProps
  } = props;

  return (
    <Box sx={{ minWidth: 120 }} className={className}>
      {dropdownLabel && (
        <label className="block text-base font-medium text-gray-700">
          {dropdownLabel}
          {dropdownLabel
            ? required && <span className="text-[#407BFF]">*</span>
            : ""}
        </label>
      )}
      <FormControl fullWidth {...otherProps} className=" rounded-xl">
        <InputLabel id={`${id}-label`}>
          {label}
          {label ? required && <span className="text-[#407BFF]">*</span> : ""}
        </InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          label={label}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          multiple={multiple}
          readOnly={readonly}
          style={readonly ? { cursor: "not-allowed", color: "#718096" } : {}}
          className={`${
            readonly && "cursor-not-allowed  text-gray-400"
          }  rounded-xl shadow border py-[1.75rem]  border-stone-300 justify-start items-center gap-2.5 inline-flex w-full outline-none h-11 ${
            styleClassName && styleClassName
          }`}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.label}
              value={option.value}
              className="rounded-xl "
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error && <div className="text-red-500 text-sm">{error}</div>}
      </FormControl>
    </Box>
  );
}
