import React from "react";
import { useNavigate } from "react-router-dom";
import { Master_User, Super_User } from "../../Assets/Constant";

const CustomHeader = (props) => {
  const navigate = useNavigate();

  const userRole = localStorage.getItem("userRole");

  const handleNavigate = () => {
    if (userRole === Super_User || userRole === Master_User) {
      navigate("/users");
    } else {
      navigate("#");
    }
  };
  return (
    <>
      <div className="bg-white flex justify-between  items-center z-[2] px-5 h-[4.5rem] shadow">
        <div onClick={handleNavigate} className="cursor-pointer">
          <img src={props.logo} alt="" width={200} />
        </div>
        <div>{props.children}</div>
      </div>
    </>
  );
};

export default CustomHeader;
